<template>
  <div class="col-12">
    <div class="card">
      <h5>Ver Detalle</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="">Nombre</label>
          <InputText type="text" disabled v-model="sucursal.nombre" />
          <!-- {{ sucursal.nombre ?? '' }} -->
        </div>
        <div class="field col-12 md:col-6">
          <label for="">Tel&eacute;fono</label>
          <InputText type="text" disabled v-model="sucursal.telefono" />
          <!-- {{ sucursal.nombre ?? '' }} -->
        </div>
        <div class="field col-12">
          <label for="">Direcci&oacute;n</label>
          <Textarea v-model="sucursal.direccion" disabled rows="4" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Estado</label>
          <InputText type="text" disabled v-model="texto_estado" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Creaci&oacute;n</label>
          <InputText type="text" disabled v-model="sucursal.created_at" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Actualizaci&oacute;n</label>
          <InputText type="text" disabled v-model="sucursal.updated_at" />
        </div>
        <div class="field col-12 md:col-1">
          <Button @click="goBack">
            <i class="pi pi-arrow-circle-left"></i>
            &nbsp;ATR&Aacute;S
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SucursalService from "@/service/SucursalService";

export default {
  data() {
    return {
      sucursal: {},
      texto_estado : '',
      idSucursal: this.$route.params.id,
    };
  },
  sucursalService: null,
  created() {
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.sucursalService.showSucursal(this.idSucursal).then((data) => {
      this.sucursal = data;
      this.texto_estado = this.textoEstado(data.estado);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
};
</script>
